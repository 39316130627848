import React from "react";
import {
	Grid,
	TableContainer,
	Paper,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";

const MiscellaneousGrid = ({
	miscellaneousFields,
	currentData,
	formatDate,
}) => {
	return (
		<Grid container spacing={2} alignItems='center' justifyContent='center'>
			<Grid item>
				<TableContainer component={Paper} style={{ display: "inline-block" }}>
					<Table>
						<TableBody>
							{Object.keys(miscellaneousFields).map((field) => (
								<TableRow key={field}>
									<TableCell>{miscellaneousFields[field]}</TableCell>
									<TableCell>
										{field === "MessageDate" ? (
											formatDate(currentData[field])
										) : field === "Revised" ? (
											<span
												style={{
													color: currentData[field] ? "green" : "red",
												}}>
												{currentData[field] ? "Si" : "No"}
											</span>
										) : (
											currentData[field]
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
};

export default MiscellaneousGrid;
