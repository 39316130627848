import React, { useState, useEffect, useCallback } from "react";
import { Snackbar, Alert, CircularProgress, Grid, Box } from "@mui/material";

import MessageHistoryTable from "../../components/Administration/MessageHistoryTable";
import Navigation from "../../components/Administration/Navigation";
import IncidenceGrid from "../../components/Administration/IncidenceGrid";
import MiscellaneousGrid from "../../components/Administration/MiscellaneousGrid";
import IsCoveredGrid from "../../components/Administration/IsCoveredGrid";
import ImageCard from "../../components/Administration/ImageCard";

import DeviceService from "../../services/DeviceService/DeviceService.js";
import {
	fetchDevicesLastMessage,
	fetch10LastDeviceMessages,
	toggleCorrect,
	formatDate,
} from "./scripts/adminViewFunctions.js";
import {
	updateIsCoveredCorrect,
	updateRevised,
} from "../../services/AdminViewService/AdminViewService.js";
import "./css/AdminView.css";

export default function AdminDevice() {
	const [openSnackbar, setOpenSnackBar] = useState(false);
	const [data, setData] = useState([]);
	const [deviceData, setDeviceData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(
		parseInt(localStorage.getItem("currentIndex"), 10) || 0
	);
	const [selectedIncidence, setSelectedIncidence] = useState("Otros");
	const [isCoveredCorrectState, setIsCoveredCorrectState] = useState(false);
	const [inputId, setInputId] = useState("");

	useEffect(() => {
		localStorage.setItem("currentIndex", currentIndex);
		if (data.length !== 0) {
			fetch10LastDeviceMessages(
				data,
				DeviceService,
				currentIndex,
				setDeviceData,
				setOpenSnackBar
			);
		}
		setIsCoveredCorrectState(data[currentIndex]?.IsCoveredCorrect || false);
	}, [currentIndex, data]);

	useEffect(() => {
		const fetchData = async () => {
			if (data.length === 0) {
				await fetchDevicesLastMessage(
					data,
					setData,
					DeviceService,
					setLoading,
					setOpenSnackBar
				);
			}
		};
		fetchData();
	}, [data.length]);

	const handleKeyDown = useCallback(
		(e) => {
			if (e.key === "ArrowRight" && currentIndex < data.length - 1) {
				updateRevised(data, setData, currentIndex, true, setOpenSnackBar);
				setCurrentIndex((prevIndex) => prevIndex + 1);
			} else if (e.key === "ArrowLeft" && currentIndex > 0) {
				updateRevised(data, setData, currentIndex, false, setOpenSnackBar);
				setCurrentIndex((prevIndex) => prevIndex - 1);
			} else if (e.code === "Space") {
				updateIsCoveredCorrect(
					data,
					setData,
					currentIndex,
					toggleCorrect,
					isCoveredCorrectState,
					setIsCoveredCorrectState,
					setOpenSnackBar
				);
			}
		},
		[currentIndex, data, isCoveredCorrectState]
	);

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleKeyDown]);

	const dataFields = {
		MessageDate: "Fecha del Mensaje",
		OCRValue: "Lectura",
		Incidence: "Incidencia",
	};

	const miscellaneousFields = {
		id: "Id",
		MessageId: "Id del Mensaje",
		Device: "IMEI",
	};

	const incidenceTypes = [
		"Otros",
		"Equipo descolocado",
		"Falta cobertura",
		"Falta de nitidez imagen",
		"Cambiar batería",
		"OCR incorrecto",
	];

	const deviceDataFields = {
		imagePath: "Imagen",
		value: "Lectura",
		createdDate: "Fecha del Mensaje",
		electricity: "Batería",
	};

	return (
		<>
			{loading ? (
				<CircularProgress />
			) : (
				data &&
				data.length > 0 && (
					<Grid container spacing={3}>
						{/* Column 1 */}
						<Grid item xs={12} sm={6} md={6}>
							{/* Image and Data */}
							<Box mb={4}>
								<ImageCard
									data={data}
									currentIndex={currentIndex}
									dataFields={dataFields}
									formatDate={formatDate}
								/>
							</Box>

							{/* IsCovered */}
							<Grid item xs={12}>
								<IsCoveredGrid
									data={data}
									currentIndex={currentIndex}
									updateIsCoveredCorrect={updateIsCoveredCorrect}
									isCoveredCorrectState={isCoveredCorrectState}
									toggleCorrect={toggleCorrect}
									setIsCoveredCorrectState={setIsCoveredCorrectState}
									setOpenSnackBar={setOpenSnackBar}
								/>
							</Grid>
						</Grid>

						{/* Column 2 */}
						<Grid item xs={12} sm={6} md={6}>
							<Grid
								container
								style={{ height: "100%" }}
								spacing={4}
								alignItems='center'
								justifyContent='center'>
								{/* Miscellaneous Data */}
								<Grid item>
									<MiscellaneousGrid
										miscellaneousFields={miscellaneousFields}
										currentData={data[currentIndex]}
										formatDate={formatDate}
									/>
								</Grid>

								{/* Incidence */}
								<Grid item>
									<IncidenceGrid
										setSelectedIncidence={setSelectedIncidence}
										selectedIncidence={selectedIncidence}
										incidenceTypes={incidenceTypes}
									/>
								</Grid>

								{/* Navigation */}
								<Grid item>
									<Navigation
										setCurrentIndex={setCurrentIndex}
										data={data}
										inputId={inputId}
										setInputId={setInputId}
										setOpenSnackBar={setOpenSnackBar}
									/>
								</Grid>
							</Grid>
						</Grid>

						{/* Column 3 */}
						<Grid item xs={12}>
							{/* Message History */}
							<MessageHistoryTable
								deviceData={deviceData}
								deviceDataFields={deviceDataFields}
								formatDate={formatDate}
							/>
						</Grid>
					</Grid>
				)
			)}
			<Snackbar
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackBar(false)}>
				<Alert
					onClose={() => setOpenSnackBar(false)}
					severity='error'
					variant='filled'
					sx={{ width: "100%" }}>
					Failed to update
				</Alert>
			</Snackbar>
		</>
	);
}
