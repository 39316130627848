import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useMediaQuery } from 'react-responsive'
import { Riple } from "react-loading-indicators";

export const StackedBarChart = (props) => {
    const {
        getDataStackedBarChart,
        service,
        xDataKey,
        barSize,
        firstBarDataKey,
        firstBarColor,
        CustomTooltip,
        LegendPayload,
        secondBarDataKey = null,
        secondBarColor = null,
        thirdBarDataKey = null,
        thirdBarColor = null,
        fourthBarDataKey = null,
        fourthBarColor = null,
        loaderSize = "small",
        loaderColor = "#7ab750",
        loaderText = "",
        loaderTextColor = ""
    } = props;

    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 })

    const formatXAxisTick = (value) => {
        const maxLength = 12;
        if (value.length > maxLength) { return value.slice(0, maxLength) + '...'; }
        return value;
    };

    useEffect(() => { getDataStackedBarChart(service, setContent, setLoading); }, []);

    return (
        <ResponsiveContainer width="100%" height={ 400}>
            {loading ? 
                <Riple color={loaderColor} size={loaderSize} text={loaderText} textColor={loaderTextColor} />
                :
                <BarChart
                    data={content}
                    margin={{
                        top: 20, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={xDataKey} tick={{ fontSize: 14, fill: '#666', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontWeight: 'bold', }} tickFormatter={formatXAxisTick} />
                    <YAxis />
                    <Tooltip content={<CustomTooltip firstColor={firstBarColor} secondColor={secondBarColor} thirdColor={thirdBarColor} fourthColor={fourthBarColor}  />} />
                    <Legend
                        wrapperStyle={{ paddingTop: 20 }}
                        payload={LegendPayload({ firstColor: firstBarColor, secondColor: secondBarColor, thirdColor: thirdBarColor, fourthColor: fourthBarColor })}
                    />
                    <Bar dataKey={firstBarDataKey} fill={firstBarColor} barSize={barSize} stackId="a" />
                    {secondBarDataKey ? <Bar dataKey={secondBarDataKey} fill={secondBarColor} barSize={barSize} stackId="a" /> : <></>}
                    {thirdBarDataKey ? <Bar dataKey={thirdBarDataKey} fill={thirdBarColor} barSize={barSize} stackId="a" /> : <></>}
                    {fourthBarDataKey ? <Bar dataKey={fourthBarDataKey} fill={fourthBarColor} barSize={barSize} stackId="a" /> : <></>}
                </BarChart>
            }
        </ResponsiveContainer>
    );
};