import React, { useState } from "react";
import {
	Collapse,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	NavItem,
	NavLink,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./css/NavMenu.css";
import logo from "./img/redexis_logo.png";
import AuthState from "./../AuthState/AuthState";

export function NavMenu() {
	const [collapsed, setCollapsed] = useState(true);
	const { loggedIn, logoutUrl } = AuthState();
	const toggleNavbar = () => {
		setCollapsed(!collapsed);
	};
	const location = useLocation();
	const [activeLink, setActiveLink] = useState("/dashboard");

	const handleSetActiveLink = (link) => {
		setActiveLink(link);
	};

	return (
		<header>
			<Navbar
				className='navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3'
				container
				light>
				<NavbarBrand tag={Link} to='/dashboard'>
					<img src={logo} height='36' />
					Redexis Portal
				</NavbarBrand>
				<NavbarToggler onClick={toggleNavbar} className='mr-2' />
				<Collapse
					className='d-sm-inline-flex flex-sm-row-reverse'
					isOpen={!collapsed}
					navbar>
					<ul className='navbar-nav flex-grow'>
						{loggedIn && (
							<>
								<NavItem>
									<NavLink
										tag={Link}
										className={`text-dark ${
											location.pathname === "/dashboard" ? "active" : ""
										}`}
										to='/dashboard'
										onClick={() => handleSetActiveLink("/dashboard")}>
										Dashboard
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										tag={Link}
										className={`text-dark ${
											location.pathname === "/adminDevice" ? "active" : ""
										}`}
										to='/adminDevice'
										onClick={() => handleSetActiveLink("/adminDevice")}>
										Devices
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										tag={Link}
										className={`text-dark ${
											location.pathname === "/adminDevicesIncidences"
												? "active"
												: ""
										}`}
										to='/adminDevicesIncidences'
										onClick={() =>
											handleSetActiveLink("/adminDevicesIncidences")
										}>
										Incidences
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										tag={Link}
										className={`text-dark ${
											location.pathname === "/adminView" ? "active" : ""
										}`}
										to='/adminView'
										onClick={() => handleSetActiveLink("/adminView")}>
										AdminView
									</NavLink>
								</NavItem>
							</>
						)}
						<NavItem>
							<a
								className='text-dark nav-link'
								href={loggedIn ? logoutUrl : "/bff/login"}>
								{" "}
								{loggedIn ? "Logout" : "Login"}{" "}
							</a>
						</NavItem>
					</ul>
				</Collapse>
			</Navbar>
		</header>
	);
}
