import React, { useState, useCallback } from "react";

// #region DataGridPro
import SignosDataGridPro from "../../components/DataGrid/SignosDataGridPro/SignosDataGridPro";
import DataGridMessageDetail from "../../components/DataGrid/SignosDataGridPro/accessories/DetailPanel";
// #endregion

//#region Grid Devices functions
import {
	lastDeviceMessagesExists,
	fetchDevicesLastMessage,
	getGridDevicesColumns,
	getGridDevicesInitialState,
	getGridDevicesRowClass,
	getGridDevicesCellClass /*, fetchDataFromServer*/,
} from "./scripts/gridDevicesFunctions";
// #endregion

import { Snackbar, Alert } from "@mui/material";

//#region Detail Grid Messages functions

import {
	messagesFromDeviceExists,
	fetchMessagesFromDevice,
	getGridDetailMessagesFromDeviceColumns,
	getGridDetailMessagesFromDeviceInitialState,
	getGridDetailMessagesFromDeviceRowClass,
	getGridDetailMessagesFromDeviceCellClass,
} from "./scripts/gridDetailMessagesFromDevice";
// #endregion

// #region Services
import DeviceService from "../../services/DeviceService/DeviceService.js";
// #endregion

export default function AdminDevice() {
	// #region functions From DataGridDetails

	const [isOpen, setIsOpen] = useState(false);
	const [openPanelId, setOpenPanelId] = useState(null);
	const [reloadTrigger, setReloadTrigger] = useState(0);
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const handlePanelChange = useCallback(
		(newId) => {
			if (isOpen && openPanelId !== newId) {
				setIsOpen(false);
			} else {
				setIsOpen(!isOpen);
				setOpenPanelId(newId);
			}
		},
		[isOpen, openPanelId]
	);

	const getMessagesDetailPanelContent = useCallback(
		(props) => {
			const { id } = props;
			return (
				<DataGridMessageDetail
					fatherID={id}
					isOpen={openPanelId === id}
					onToggle={() => handlePanelChange(id)}
					serviceDetail={DeviceService}
					dataDetailRowsExists={messagesFromDeviceExists}
					fetchDataDetailRows={fetchMessagesFromDevice}
					getDataDetailsColumns={getGridDetailMessagesFromDeviceColumns()}
					initialState={getGridDetailMessagesFromDeviceInitialState()}
					getRowDetailClass={getGridDetailMessagesFromDeviceRowClass}
					getCellDetailClass={getGridDetailMessagesFromDeviceCellClass}
					tableHeight={500}
					defaultPagesize={15}
				/>
			);
		},
		[handlePanelChange, openPanelId]
	);

	// #endregion

	return (
		<>
			<SignosDataGridPro
				// #region Required
				service={DeviceService}
				dataRowsExists={lastDeviceMessagesExists}
				fetchDataRows={fetchDevicesLastMessage}
				getDataColumns={getGridDevicesColumns(
					setReloadTrigger,
					setOpenSnackbar
				)}
				initialState={getGridDevicesInitialState()}
				getRowClassName={getGridDevicesRowClass}
				getCellClassName={getGridDevicesCellClass}
				reloadTrigger={reloadTrigger}
				// #endregion

				// #region Styles optional
				tableHeight={800}
				rowHeight={50}
				defaultPagesize={50}
				// #endregion

				// #region With DetailPanel
				withDetailPanel={true}
				//visibleRowsDetailPanel={0}
				getDetailPanelContent={getMessagesDetailPanelContent}
				//#endregion
			/>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity='error'
					variant='filled'
					sx={{ width: "100%" }}>
					Failed to update
				</Alert>
			</Snackbar>
		</>
	);
}
