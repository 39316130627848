import { DeviceLastMessage } from "./../../../entities/DeviceLastMessage";
import { OCRMessage } from "./../../../entities/OCRMessage";

export const createDeviceLastMessage = (deviceMessage) => {
	let deviceLastMessage = new DeviceLastMessage(
		deviceMessage.id,
		deviceMessage.imei,
		deviceMessage.deposit?.industrialZone?.location?.redexisZone?.name,
		deviceMessage.deposit?.industrialZone?.location?.name,
		deviceMessage.deposit?.industrialZone?.code,
		deviceMessage.deposit?.code,
		deviceMessage.deposit?.capacity,
		deviceMessage.deposit?.suffix,
		deviceMessage.value,
		deviceMessage.lastMessage,
		deviceMessage.networkType,
		deviceMessage.electricity,
		deviceMessage.imagePath,
		deviceMessage.statuses?.length !== 0,
		deviceMessage.statuses?.length !== 0 ? deviceMessage.statuses[0]?.name : "",
		null,
		deviceMessage.depoCodeJSON,
		deviceMessage.suffixJSON,
		deviceMessage.industrialZoneJSON,
		deviceMessage.locationJSON,
		deviceMessage.locationJSONRaw,
		deviceMessage.covered,
		deviceMessage.messageId,
		deviceMessage.isCoveredCorrect
	);
	return deviceLastMessage;
};

export const createOCRMessage = (message) => {
	let ocrMessage = new OCRMessage(
		message.id,
		message.deviceId,
		message.dataType,
		message.eventType,
		message.imagePath,
		message.value,
		message.networkType,
		message.electricity,
		message.messageIdRef,
		message.createdDate,
		message.location,
		message.locationRaw,
		message.industrialZone,
		message.depoCode,
		message.suffix
	);
	return ocrMessage;
};
