
//#region InfoCards
export const getDashboardLastMessageDate = (service, setContent, setLoading) => {
    (async () => {
        try {
            setLoading(true);
            const lastMessage = await service.getLastMessageDate() ?? null;
            if (lastMessage ?? lastMessage.success) {
                let date = new Date(lastMessage.result);
                date = date.toLocaleString('es-ES', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' });
                if (date) { setContent(date); }
                else { setContent("ERROR"); }
            } else { setContent("ERROR"); }
            setLoading(false);
        }
        catch (error) {
            console.error(error);
            setContent("ERROR");
            setLoading(false);
        }
    })();
}
export const getDashboardTotalIncidences = (service, setContent, setLoading) => {
    (async () => {
        try {
            setLoading(true);
            const totalIncidences = await service.getTotalIncidences() ?? null;
            if (totalIncidences ?? totalIncidences.success) {
                if (totalIncidences) { setContent(totalIncidences.result); }
                else { setContent("ERROR"); }
            } else { setContent("ERROR"); }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setContent("ERROR");
            setLoading(false);
        }
    })();
}
export const getDashboardTotalDevices = (service, setContent, setLoading) => {
    (async () => {
        try {
            setLoading(true);
            const devicesByValueRange = await service.getDevicesByValueRange() ?? null;
            if (devicesByValueRange ?? devicesByValueRange.success) {
                let content = devicesByValueRange.result;
                let totalDevices = content.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.total;
                }, 0);
                if (totalDevices) { setContent(totalDevices); }
                else { setContent("ERROR"); }
            } else { setContent("ERROR"); }
            setLoading(false);
        }
        catch (error) {
            console.error(error);
            setContent("ERROR");
            setLoading(false);
        }
    })();
}
//#endregion

//#region CustomQuantityAreaChartIncidencesByMonth
export const getDashboardIncidencesByMonthAreaChart = (service, setContent, setLoading) => {
    (async () => {
        try {
            setLoading(true);
            const totalIncidencesByMonth = await service.getIncidencesByMonth() ?? null;
            if (totalIncidencesByMonth ?? totalIncidencesByMonth.success) {
                if (totalIncidencesByMonth) {
                    let content = totalIncidencesByMonth.result;
                    let dataChart = [];
                    if (content) {
                        content.sort((a, b) => {
                            if (a.year !== b.year) {
                                return a.year - b.year; // Ordena primero por año
                            }
                            return a.month - b.month; // Si el año es el mismo, ordena por mes
                        });
                        content.forEach((item, index) => {                         
                            dataChart.push({
                                key: index,
                                concatMonthAndYear: getConcatMonthAndYearStringBarChart(item.month, item.year),
                                totalActive: item.totalActive,
                                totalInactive: item.totalInactive,
                                accumulated: item.accumulated,
                            });
                        });
                    }
                    setContent(dataChart);
                }
                else { setContent("ERROR"); }
            } else { setContent("ERROR"); }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setContent("ERROR");
            setLoading(false);
        }
    })();
}
export const renderTooltipContentIncidencesByMonthAreaChart = ({ payload, label, content }) => {
    const total = payload.reduce((result, entry) => result + entry.value, 0);
    const currentMonthData = content.find((item) => item.concatMonthAndYear === label);
    const totalIncidences = currentMonthData ? currentMonthData.accumulated : 0;

    return (
        <div className="customized-tooltip-content" style={{ fontWeight: 'bold' }}>
            <p className="total">{`Total Incidencias Activas hasta este mes:  ${totalIncidences}`}</p>
            <p className="total">{`${label} (Total Mes: ${total})`}</p>
            <ul className="list">
                {payload.map((entry, index) => (
                    <li key={`item-${index}`} style={{ color: entry.color }}>
                        {`${entry.value} ${entry.name === "totalActive" ? "Pendientes" : entry.name === "totalInactive" ? "Solucionadas" : entry.name}`}
                    </li>
                ))}
            </ul>
        </div>
    );
};
const getConcatMonthAndYearStringBarChart = (monthNum, year) => {
    const fecha = new Date(year, monthNum - 1);
    const nombreMes = fecha.getMonth() + 1 < 10 ? `0${fecha.getMonth() + 1}` : fecha.getMonth() + 1;
    const yearShort = year.toString().substr(-2);
    return `${nombreMes}/${yearShort}`;
}
//#endregion

//#region PieChartIncidenceByType
export const getDataIncidenceByTypePieChart = (service, setContent, setLoading) => {

    (async () => {
        try {
            setLoading(true);
            const totalIncidencesByType = await service.getIncidencesByType() ?? null;
            if (totalIncidencesByType ?? totalIncidencesByType.success) {
                if (totalIncidencesByType) {
                    let content = totalIncidencesByType.result;
                    let dataChart = [];
                    if (content) {
                        content.forEach((item, index) => {
                            dataChart.push({
                                key: index,
                                name: item.name,
                                value: item.total,
                            });
                        });
                    }
                    setContent(dataChart);
                }
                else { setContent("ERROR"); }
            } else { setContent("ERROR"); }

            setLoading(false);
        } catch (error) {
            console.error(error);
            setContent("ERROR");
            setLoading(false);
        }
    })();
}
export const legendPayloadPieCharts = ({ content, COLORS }) => {
    return content.map((entry, index) => ({
        id: entry.name,
        type: 'square',
        value: <><strong>{entry.value}</strong> {entry.name}</>,
        color: COLORS[index % COLORS.length],
    }));
}
export const renderTooltipIncidenceByType = ({ active, payload, content }) => {
    if (active && payload && payload.length) {
        const sectionName = payload[0].name;
        const section = content.find(entry => entry.name === sectionName);
        if (section) {
            return (
                <div className="custom-tooltip" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    <p className="label">{`${section.name}: ${section.value}`}</p>
                </div>
            );
        }
    }
    return null;
};
export const renderPieChartLabelIncidenceByType = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + (radius + 10) * Math.cos(-midAngle * RADIAN);
    const y = cy + (radius + 10) * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="#fff" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: '20px', fontWeight: 'bold' }}>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};
//#endregion

//#region StackedBarChartDepositValueByZone
export const getDataDepositValueByZoneBarChart = async (service, setContent, setLoading) => {
    try {
        setLoading(true);
        const devicesByValueRangeByZone = await service.getDevicesByValueRangeByZone() ?? null;
        if (devicesByValueRangeByZone && devicesByValueRangeByZone.success) {
            const result = devicesByValueRangeByZone.result.map(item => ({
                ...item,
                rangeGroup: item.rangeGroup === null ? "Sin Ubicar" : item.rangeGroup
            }));
            const dataChart = result.reduce((dataChartArray, contentItem) =>
            {
                const zoneIndex = dataChartArray.findIndex(item => item.zone === contentItem.rangeGroup);
                if (zoneIndex >= 0)
                {
                    dataChartArray[zoneIndex].a += contentItem.startRange === 0 && contentItem.endRange === 30 ? contentItem.total : 0;
                    dataChartArray[zoneIndex].b += contentItem.startRange === 30 && contentItem.endRange === 50 ? contentItem.total : 0;
                    dataChartArray[zoneIndex].c += contentItem.startRange === 50 && contentItem.endRange === 75 ? contentItem.total : 0;
                    dataChartArray[zoneIndex].d += contentItem.startRange === 75 && contentItem.endRange === 100 ? contentItem.total : 0;
                }
                else
                {
                    const { rangeGroup } = contentItem;
                    dataChartArray.push({
                        zone: rangeGroup,
                        a: contentItem.startRange === 0 && contentItem.endRange === 30 ? contentItem.total : 0,
                        b: contentItem.startRange === 30 && contentItem.endRange === 50 ? contentItem.total : 0,
                        c: contentItem.startRange === 50 && contentItem.endRange === 75 ? contentItem.total : 0,
                        d: contentItem.startRange === 75 && contentItem.endRange === 100 ? contentItem.total : 0,
                    });
                }
                return dataChartArray;
            }, []);
            setContent(dataChart);
        }
        else { setContent("ERROR"); }
        setLoading(false);
    } catch (error) {
        console.error(error);
        setContent("ERROR");
        setLoading(false);
    }
};
export const customTooltipDepositValueByZoneBarChart = ({ active, payload, label, firstColor, secondColor, thirdColor, fourthColor }) => {
    if (active && payload) {
        const total = payload.reduce((result, entry) => result + entry.value, 0);
        const a = payload.find((entry) => entry.name === 'a')?.value || 0;
        const b = payload.find((entry) => entry.name === 'b')?.value || 0;
        const c = payload.find((entry) => entry.name === 'c')?.value || 0;
        const d = payload.find((entry) => entry.name === 'd')?.value || 0;

        return (
            <div className="custom-tooltip">
                <p className="label" style={{ fontWeight: 'bold' }}><u>{`${label}`}</u></p>
                <p className="total" style={{ fontWeight: 'bold' }}>{`Total: ${total}`}</p>
                <p className="value" style={{ color: firstColor, fontWeight: 'bold' }}> {`De 0 a 30 => ${a}`} </p>
                <p className="value" style={{ color: secondColor, fontWeight: 'bold' }}> {`De 30 a 50 => ${b}`} </p>
                <p className="value" style={{ color: thirdColor, fontWeight: 'bold' }}> {`De 50 a 75 => ${c}`} </p>
                <p className="value" style={{ color: fourthColor, fontWeight: 'bold' }}> {`De 75 a 100 => ${d}`} </p>
            </div>
        );
    }
    return null;
};
export const legendPayloadDepositValueByZoneBarChart = ({ firstColor, secondColor, thirdColor, fourthColor }) => {
    return [
        { value: "De 0 a 30", type: "square", color: firstColor, id: "a" },
        { value: "De 30 a 50", type: "square", color: secondColor, id: "b" },
        { value: "De 50 a 75", type: "square", color: thirdColor, id: "c" },
        { value: "De 75 a 100", type: "square", color: fourthColor, id: "d" },
    ];
}
//#endregion

//#region PieChartDeviceByValueRange
export const getDataDeviceByValueRange = (service, setContent, setLoading) => {

    (async () => {
        try {
            setLoading(true);
            const devicesByValueRange = await service.getDevicesByValueRange() ?? null;
            if (devicesByValueRange ?? devicesByValueRange.success) {
                if (devicesByValueRange) {
                    let content = devicesByValueRange.result;
                    let dataChart = [];
                    if (content) {
                        content.forEach((item, index) => {
                            dataChart.push({
                                key: index,
                                name: `=> De ${item.startRange} a ${item.endRange}`  ,
                                value: item.total,
                            });
                        });
                    }
                    setContent(dataChart);
                }
                else { setContent("ERROR"); }
            } else { setContent("ERROR"); }

            setLoading(false);
        } catch (error) {
            console.error(error);
            setContent("ERROR");
            setLoading(false);
        }
    })();
}
export const renderTooltipDeviceByValueRange = ({ active, payload, content }) => {
    if (active && payload && payload.length) {
        const sectionName = payload[0].name;
        const section = content.find(entry => entry.name === sectionName);
        if (section) {
            return (
                <div className="custom-tooltip" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    <p className="label">{`${section.name.substring(3)}: ${section.value}`}</p>
                </div>
            );
        }
    }
    return null;
};
export const renderPieChartLabelDeviceByValueRange = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + (radius + 10) * Math.cos(-midAngle * RADIAN);
    const y = cy + (radius + 10) * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="#fff" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: '20px', fontWeight: 'bold' }}>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};
//#endregion


//#region Sin uso
 const getDashboardTotalMessages = (service, setContent, setLoading) => {
    (async () => {
        try {
            setLoading(true);
            const totalMessage = await service.getTotalMessages() ?? null;
            if (totalMessage ?? totalMessage.success) {
                if (totalMessage) { setContent(totalMessage.result); }
                else { setContent("ERROR"); }
            } else { setContent("ERROR"); }
            setLoading(false);
        }
        catch (error) {
            console.error(error);
            setContent("ERROR");
            setLoading(false);
        }
    })();
}
 const getDashboardIncidencesByType = (service, setContent, setLoading) => {
    (async () => {
        try {
            setLoading(true);
            const totalIncidencesByType = await service.getIncidencesByType() ?? null;
            if (totalIncidencesByType ?? totalIncidencesByType.success) {
                if (totalIncidencesByType) { setContent(totalIncidencesByType.result); }
                else { setContent("ERROR"); }
            } else { setContent("ERROR"); }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setContent("ERROR");
            setLoading(false);
        }
    })();
}
//#endregion