import { useState } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import Modal from "react-modal";
import DeviceService from "../../../../services/DeviceService/DeviceService";
import { MdAddchart } from "react-icons/md";
import Button from '@mui/material/Button';
import { useMediaQuery } from 'react-responsive'
import { Riple } from "react-loading-indicators";

const CustomChartCell = (props) => {

    const {
        deviceId,
        getDataContent,
        loaderSize = "small",
        loaderColor = "#7ab750",
        loaderText = "",
        loaderTextColor = ""
    } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const chartDimensions = {
        width: "100%",
        height: isMobile ? "90%" : "100%",
    };
    const modalDimensions = {
        width: isMobile ? "98%" : "56%",
        height: isMobile ? "55%" : "80%",
    };

    const handleClick = () => {
        (async () => {
            setIsModalOpen(true);
            getDataContent(deviceId, DeviceService, data, setData, setLoading);
        })();
    }

    const handleModalClose = () => { setIsModalOpen(false); }

    return (
        <>
            <Button onClick={handleClick}>
                <MdAddchart style={{ fontSize: '200%' }} />
            </Button>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleModalClose}
                //style={modalStyles}

                style={{
                    overlay: {
                        background: "transparent",
                        border: "0",
                        overflow: "hidden",
                        zIndex: '9999'
                    },
                    content: {
                        position: "absolute",
                        top: isMobile ? "20%" : "50%",
                        left: isMobile ? "10%" : "72%",
                        transform: isMobile ? "translate(-10%, -10%)" : "translate(-50%, -50%)",
                        ...modalDimensions,
                    },
                }}


                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
            >
                {loading ?
                    <Riple color={loaderColor} size={loaderSize} text={loaderText} textColor={loaderTextColor} />
                    :
                    <div style={{ minWidth: "50vw", minHeight: "50vh", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ width: "100%", height: "100%" }}>
                            <ResponsiveContainer {...chartDimensions}>
                                <AreaChart
                                    data={data}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis domain={[0, 100]} />
                                    <Tooltip />
                                    <Area
                                        type="monotone"
                                        dataKey="value"
                                        stroke="#8884d8"
                                        fill="#8884d8"
                                    />
                                </AreaChart>
                            </ResponsiveContainer>
                            <Button
                                style={{ position: "absolute", top: 0, right: 0 }}
                                onClick={() => setIsModalOpen(false)}> X
                            </Button>
                        </div>
                    </div>
                }
            </Modal>
        </>
    );
};

export default CustomChartCell;