import { useState, useEffect } from 'react';

const AuthState = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [logoutUrl, setLogoutUrl] = useState("/bff/logout");

    const fetchIsUserLoggedIn = async () => {
        try {
            const response = await fetch("/bff/user", {
                headers: {
                    "X-CSRF": 1,
                },
            });

            if (response.ok && response.status === 200) {
                const data = await response.json();
                const logoutUrl = data.find((claim) => claim.type === "bff:logout_url")?.value ?? setLogoutUrl;
                setLoggedIn(true);
                setLogoutUrl(logoutUrl);
            } else {
                setLoggedIn(false);
            }
        } catch (e) {
            console.error(e);
            setLoggedIn(false);
        }
    };

    useEffect(() => {
        fetchIsUserLoggedIn();
    }, []);

    return {
        loggedIn,
        logoutUrl
    };
};

export default AuthState;
