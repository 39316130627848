import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout/Layout";
import AdminDevice from "./views/AdminDevice/AdminDevice";
import AdminDevicesIncidences from "./views/AdminDevicesIncidences/AdminDevicesIncidences";
import AdminView from "./views/AdminView/AdminView";
import Dashboard from "./views/Dashboard/Dashboard";
import ProtectedRoute from "./components/Router/ProtectedRoute";

export default class App extends Component {
	render() {
		return (
			<Layout>
				<Routes>
					<Route
						index
						path=''
						element={
							<ProtectedRoute>
								{" "}
								<Dashboard />{" "}
							</ProtectedRoute>
						}
					/>
					<Route path='/bff/login' />
					<Route
						path='/dashboard'
						element={
							<ProtectedRoute>
								{" "}
								<Dashboard />{" "}
							</ProtectedRoute>
						}
					/>
					<Route
						path='/adminDevice'
						element={
							<ProtectedRoute>
								{" "}
								<AdminDevice />{" "}
							</ProtectedRoute>
						}
					/>
					<Route
						path='/adminDevicesIncidences'
						element={
							<ProtectedRoute>
								{" "}
								<AdminDevicesIncidences />{" "}
							</ProtectedRoute>
						}
					/>
					<Route
						path='/adminView'
						element={
							<ProtectedRoute>
								{" "}
								<AdminView />{" "}
							</ProtectedRoute>
						}
					/>
				</Routes>
			</Layout>
		);
	}
}
