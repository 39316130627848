const getGridDevicesRows = (devices) => {
	let initialDevicesLastMessageRows = [];

	if (devices && devices.length > 0) {
		devices.map((item) => {
			let value = {};
			value.id = item.id;
			value.Device = item.imei;
			value.Zone = item.zone;
			value.Location = item.location;
			value.IndustrialZone = item.industrialZone;
			value.Deposit = item.deposit;
			value.Capacity = item.capacity;
			value.Suffix = item.suffix;
			value.OCRValue = item.value;
			value.MessageDate = item.lastMessage;
			value.Network = item.networkType;
			value.BatteryCapacity = item.electricity;
			value.OCRImage = item.imagePath;
			value.Incidence_Active = item.incidenceActive;
			value.Incidence = item.incidence;
			value.OCRChart = item.ocrChart;
			value.Covered = item.covered;
			value.MessageId = item.messageId;
			value.IsCoveredCorrect = item.isCoveredCorrect;

			if (!initialDevicesLastMessageRows.find((x) => x.id === value.id)) {
				if (value.Deposit) {
					value.Deposit_Active = true;
				} else {
					// TO DO: Aún no se registra la Zona Redexis (zone_JSON) en OCRMessages
					value.Zone = item.lastMessage === null ? "SIN MENSAJES" : "NO REG";
					value.Location =
						item.location_JSON ?? item.deposit_JSON ?? "SIN MENSAJES";
					value.IndustrialZone =
						item.industrialZone_JSON ?? item.deposit_JSON ?? "SIN MENSAJES";
					value.Suffix =
						item.suffix_JSON ?? item.deposit_JSON ?? "SIN MENSAJES";
					value.Deposit = item.deposit_JSON ?? "SIN MENSAJES";
					value.Capacity = 0;
					value.Deposit_Active = false;
				}
				initialDevicesLastMessageRows.push(value);
			}
		});
	}
	return initialDevicesLastMessageRows;
};

export const fetchDevicesLastMessage = (
	data,
	setData,
	service,
	setLoading,
	setOpenSnackBar
) => {
	(async () => {
		try {
			setLoading(true);
			const devs = await service.getDevicesLastMessages();
			const newData = getGridDevicesRows(devs);
			newData.sort((a, b) => {
				// Create Date objects for both a and b
				const dateA = new Date(a.MessageDate);
				const dateB = new Date(b.MessageDate);
				// Set time to midnight for both to ignore the time component
				dateA.setHours(0, 0, 0, 0);
				dateB.setHours(0, 0, 0, 0);
				// First, sort by MessageDate
				const dateDifference = dateB - dateA;
				if (dateDifference !== 0) {
					return dateDifference;
				}
				// Second, sort by id if MessageDate is the same
				return a.id - b.id;
			});
			if (newData) {
				setData([...data, ...newData]);
			}
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
			setOpenSnackBar(true);
		}
	})();
};

export const fetch10LastDeviceMessages = (
	data,
	service,
	currentIndex,
	setDeviceData,
	setOpenSnackBar
) => {
	(async () => {
		try {
			const newData = await service.getAllDeviceMessagesById(
				data[currentIndex].id
			);
			newData.sort((a, b) => {
				return new Date(b.createdDate) - new Date(a.createdDate);
			});
			const last10Messages = newData.slice(1, 11);
			setDeviceData(last10Messages);
		} catch (error) {
			console.error(error);
			setOpenSnackBar(true);
		}
	})();
};

export const toggleCorrect = (isCorrect, setIsCorrect) => {
	setIsCorrect(!isCorrect);
};

export const formatDate = (isoString) => {
	const date = new Date(isoString);
	return `${String(date.getDate()).padStart(2, "0")}-${String(
		date.getMonth() + 1
	).padStart(2, "0")}-${date.getFullYear()}`;
};
