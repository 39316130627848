import React, { useState, useEffect } from 'react';
import { Riple } from "react-loading-indicators";

function ProtectedRoute({ children }) {
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);

    const fetchIsUserLoggedIn = async () => {
        try {
            const response = await fetch("/bff/user", { headers: { "X-CSRF": 1 } });

            if (response.ok && response.status === 200) {
                setLoggedIn(true);
                return;
            }

            setLoggedIn(false);

        } catch (e) { setLoggedIn(false); }

        finally { setLoading(false); }
    }

    useEffect(() => { fetchIsUserLoggedIn(); }, []);

    if (loading) {
        return <div
            style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            <Riple color="#3631cc" size="large" text="" textColor="" col-md="12" />
        </div>;
    }

    return loggedIn ? children : window.location.replace('/bff/login');

    //return children
}
export default ProtectedRoute