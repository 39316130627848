import { CustomImageCell } from '../../../components/DataGrid/SignosDataGridPro/accessories/CustomImageCell';

const getGridDevicesIncidencesRows = (incidences) => {
    let initialDevicesIncidencesRows = [];

    if (incidences && incidences.length > 0) {
        incidences.map((item) => {
            let value = {};
            value.id = item.id;
            value.CreatedDate = item.createdDate;
            value.UpdatedDate = item.updatedDate;
            value.Name = item.name;
            value.Code = item.code;
            value.DeviceId = item.deviceId;
            value.Description = item.description;
            value.IsActive = item.isActive;
            value.DeviceImei = item.deviceImei;
            value.ImagePath = item.imagePath;

            if (!initialDevicesIncidencesRows.find(x => x.id === value.id)) {
                initialDevicesIncidencesRows.push(value);
            }
        });
    }
    return initialDevicesIncidencesRows;
}

export const getGridDevicesIncidencesColumns = () => {
    return [
        {
            field: 'id', headerName: 'Id', type: 'number', hide: true,
            flex: 1, minWidth: 100, headerClassName: 'header-styles', cellClassName: 'cell-styles', headerAlign: 'center'
        },
        {
            field: 'DeviceId', headerName: 'Device Id', type: 'number', align: "center",
            flex: 1, minWidth: 140, headerClassName: 'header-styles', editable: false, headerAlign: 'center', hide: true
        },
        {
            field: 'ImagePath', headerName: '', renderCell: CustomImageCell, align: "center",
            width: 100, headerClassName: 'header-styles', cellClassName: 'cell-styles', headerAlign: 'center', disableColumnMenu: true, sortable: false
        },
        {
            field: 'DeviceImei', headerName: 'IMEI', type: 'string', align: "center",
            flex: 1, minWidth: 180, headerClassName: 'header-styles', cellClassName: 'cell-styles', headerAlign: 'center'
        },
        {
            field: 'Name', headerName: 'Incidencia', type: 'string', align: "center",
            flex: 1, minWidth: 180, headerClassName: 'header-styles', cellClassName: 'cell-styles', headerAlign: 'center'
        },
        {
            field: 'Code', headerName: 'Codigo', type: 'string', align: "center",
            flex: 1, minWidth: 100, headerClassName: 'header-styles', cellClassName: 'cell-styles', headerAlign: 'center'
        },
        {
            field: 'Description', headerName: 'Descripcion', type: 'string', align: "center",
            flex: 1, minWidth: 240, headerClassName: 'header-styles', cellClassName: 'cell-styles', headerAlign: 'center'
        },
        {
            field: 'CreatedDate', headerName: 'Creada', type: 'date', align: "center",
            flex: 1, minWidth: 180, headerClassName: 'header-styles', headerAlign: 'center',
            valueGetter: ({ value }) => value && new Date(value)
        },
        {
            field: 'UpdatedDate', headerName: 'Actualizada', type: 'date', align: "center",
            flex: 1, minWidth: 180, headerClassName: 'header-styles', headerAlign: 'center',
            valueGetter: ({ value }) => value && new Date(value)
        },
        {
            field: 'IsActive', headerName: 'Inc Activa', type: 'boolean', align: "center",
            flex: 1, minWidth: 114, headerClassName: 'header-styles', cellClassName: 'cell-styles', headerAlign: 'center'
        }
    ];
}

export const fetchDevicesIncidences = (data, setData, service, setLoading) => {
    (async () => {
        try {
            setLoading(true);
            const devs = await service.getDevicesIncidences();
            const newData = getGridDevicesIncidencesRows(devs);
            newData.sort((a, b) => {
                if (a.IsActive !== b.IsActive) {
                    return b.IsActive - a.IsActive; // Ordenar por IsActive = true primero
                }
                return new Date(b.CreatedDate) - new Date(a.CreatedDate); // Luego por CreatedDate desc
            });
            setData([...data, ...newData]);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    })();
}

export const deviceIncidencesExists = (data) => { return false; }

export function getGridDevicesIncidencesInitialState() { return { pinnedColumns: { left: ["ImagePath"] } }; }

export const getGridDevicesIncidencesRowClass = (params) => {
    const { IsActive } = params.row;
    return !IsActive ? "fixedIncidence-row" : "";
}

export const getGridDevicesIncidencesCellClass = (params) => {
    return '';
};