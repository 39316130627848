// #region Base
import React, { useState, useEffect } from 'react';
// #endregion

// #region core Components
import SignosDataGridPro from "./../SignosDataGridPro";
// #endregion

// #region @mui/material components
import Stack from '@mui/material/Stack';
// #endregion

export default function DetailPanel(props) {

    const {
        fatherID,
        isOpen,
        onToggle,
        serviceDetail,
        fetchDataDetailRows,
        getDataDetailsColumns,
        dataDetailRowsExists,
        getRowDetailClass,
        getCellDetailClass,
        tableHeight = 500,
        sortingRow,
        defaultPagesize = 10
    } = props;

    const [loading, setLoading] = useState(false);
    const [detailDataRow, setDetailDataRow] = useState([]);
    const [panelOpen, setPanelOpen] = useState(isOpen);

    const handlePanelChange = () => {
        setPanelOpen(!panelOpen);
        onToggle(!panelOpen);
    };

    useEffect(() => {
        (async () => {
  
            if (!dataDetailRowsExists(fatherID, detailDataRow)) {
                fetchDataDetailRows(fatherID, detailDataRow, setDetailDataRow, serviceDetail, setLoading);
            }
        })();
    }, []);


    return (
        <Stack
            sx={{
                flex: 1,
                p: 1,
                width: '41%',
                height: '100%',
                maxWidth: '100%',
                maxHeight: '100%',
            }}
            direction="column"
        >
            <SignosDataGridPro
                isDetailPanel={true}
                rows={detailDataRow}
                columns={getDataDetailsColumns}
                getRowClassName={getRowDetailClass}
                getCellClassName={getCellDetailClass}
                tableHeight={tableHeight}
                hideFooter={false}
                sortingRow={sortingRow}
                defaultPagesize={defaultPagesize}
            />
        </Stack>
    );
}
