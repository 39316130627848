import React from "react";
import {
	Grid,
	Box,
	Button,
	TextField,
	InputAdornment,
	IconButton,
} from "@mui/material";

const Navigation = ({
	setCurrentIndex,
	data,
	inputId,
	setInputId,
	setOpenSnackBar,
}) => {
	return (
		<Grid
			id='Navigation'
			container
			spacing={2}
			alignItems='center'
			justifyContent='center'>
			<Grid item xs={12} md={3}>
				<Box display='flex' justifyContent='center'>
					<Button
						className='navigationButton'
						variant='outlined'
						color='primary'
						onClick={() => setCurrentIndex(0)}>
						Principio
					</Button>
				</Box>
			</Grid>
			<Grid item xs={12} md={3}>
				<Box display='flex' justifyContent='center'>
					<TextField
						variant='outlined'
						value={inputId}
						onChange={(e) => setInputId(e.target.value)}
						sx={{ width: "150px" }}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										onClick={() => {
											const numInputId = Number(inputId);
											const index = data.findIndex(
												(item) => Number(item.id) === numInputId
											);

											if (index !== -1) {
												setCurrentIndex(index);
											} else {
												setOpenSnackBar(true);
												setInputId("");
											}
										}}>
										IR
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Box>
			</Grid>
			<Grid item xs={12} md={3}>
				<Box display='flex' justifyContent='center'>
					<Button
						className='navigationButton'
						variant='outlined'
						color='primary'
						onClick={() => setCurrentIndex(data.length - 1)}>
						Final
					</Button>
				</Box>
			</Grid>
		</Grid>
	);
};

export default Navigation;
