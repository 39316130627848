const getTotalMessages = async () => {
    let response = await fetch("api/dashboard/messages", { headers: { "X-CSRF": 1, }, });
    let responseBody = await response.json();
    return responseBody;
};

const getTotalIncidences = async () => {
    let response = await fetch("api/dashboard/incidences", { headers: { "X-CSRF": 1, }, });
    let responseBody = await response.json();
    return responseBody;
};

const getLastMessageDate = async (deviceStatusId) => {
    let response = await fetch("api/dashboard/lastmessage", { headers: { "X-CSRF": 1, }, });
    let responseBody = await response.json();
    return responseBody;
};

const getDevicesWithLowValueCount = async (deviceStatusId) => {
    let response = await fetch("api/dashboard/lowvaluedevices?valueThreshold=20", { headers: { "X-CSRF": 1, }, });
    let responseBody = await response.json();
    return responseBody;
};

const getIncidencesByType = async () => {
    let response = await fetch("api/dashboard/incidences/bytype", { headers: { "X-CSRF": 1, }, });
    let responseBody = await response.json();
    return responseBody;
};

const getIncidencesByMonth = async () => {
    let response = await fetch("api/dashboard/incidences/bymonth", { headers: { "X-CSRF": 1, }, });
    let responseBody = await response.json();
    return responseBody;
};

const getDevicesByValueRange = async () => {
    let response = await fetch("api/dashboard/devices/byvaluerange", { headers: { "X-CSRF": 1, }, });
    let responseBody = await response.json();
    return responseBody;
};

const getDevicesByValueRangeByZone = async () => {
    let response = await fetch("api/dashboard/devices/byvaluerange/byzone", { headers: { "X-CSRF": 1, }, });
    let responseBody = await response.json();
    return responseBody;
};

export default { getTotalMessages, getTotalIncidences, getLastMessageDate, getDevicesWithLowValueCount, getIncidencesByType, getIncidencesByMonth, getDevicesByValueRange, getDevicesByValueRangeByZone };
