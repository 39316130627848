import React from "react";
import { Grid, TextField, Button, MenuItem } from "@mui/material";

const IncidenceGrid = ({
	setSelectedIncidence,
	selectedIncidence,
	incidenceTypes,
}) => {
	return (
		<Grid container spacing={2} alignItems='center' justifyContent='center'>
			<Grid
				container
				item
				xs={12}
				spacing={2}
				alignItems='center'
				justifyContent='center'>
				<Grid item>
					<TextField
						select
						label='Tipo de Incidencia'
						value={selectedIncidence}
						onChange={(e) => setSelectedIncidence(e.target.value)}
						variant='outlined'
						style={{ minWidth: "300px" }}>
						{incidenceTypes.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item>
					<TextField
						label='Descripción de la Incidencia'
						variant='outlined'
						style={{ minWidth: "300px" }}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				item
				xs={12}
				spacing={2}
				alignItems='center'
				justifyContent='center'>
				<Grid item>
					<Button variant='contained' color='success'>
						Añadir
					</Button>
				</Grid>
				<Grid item>
					<Button variant='contained' color='error'>
						Borrar
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default IncidenceGrid;
