import React, { useState, useEffect } from "react";
import { Riple } from "react-loading-indicators";
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';

export const InfoCard = (props) => {
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(false);
    const {
        getDataCard,
        service,
        cardTitle = "title",
        cardType = "bg-success",
        cardTextColor,
        cardPadding = "mb-1",
        cardHeight = "100%",
        cardTextAlign = "text-center",
        loaderSize = "small",
        loaderColor = "#7ab750",
        loaderText = "",
        loaderTextColor = ""
    } = props;

    useEffect(() => {
        getDataCard(service, setContent, setLoading);
    }, []);


    return (
        <Card className={`card ${cardType} ${cardTextColor} ${cardTextAlign} ${cardPadding}`} style={{ height: cardHeight }}>
            <CardBody style={{ padding: '3px' }}>
                {loading ?
                    <Riple color={loaderColor} size={loaderSize} text={loaderText} textColor={loaderTextColor} />
                    :
                    <>
                        <div>
                            <CardTitle><h5>{cardTitle}</h5></CardTitle>
                            <CardText ><h4>{content}</h4></CardText>
                        </div>
                    </>
                }
            </CardBody>
        </Card>
    );
}