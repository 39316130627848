import { CustomImageCell } from "../../../components/DataGrid/SignosDataGridPro/accessories/CustomImageCell";
import CustomChartCell from "../../../components/DataGrid/SignosDataGridPro/accessories/CustomChartCell";
import { enumNetworkType } from "./../../../variables/enums";
import DeviceIncidenceService from "../../../services/DeviceIncidenceService/DeviceIncidenceService.js";

const getGridDevicesRows = (devices) => {
	let initialDevicesLastMessageRows = [];

	if (devices && devices.length > 0) {
		devices.map((item) => {
			let value = {};
			value.id = item.id;
			value.Device = item.imei;
			value.Zone = item.zone;
			value.Location = item.location;
			value.IndustrialZone = item.industrialZone;
			value.Deposit = item.deposit;
			value.Capacity = item.capacity;
			value.Suffix = item.suffix;
			value.OCRValue = item.value;
			value.MessageDate = item.lastMessage;
			value.Network = item.networkType;
			value.BatteryCapacity = item.electricity;
			value.OCRImage = item.imagePath;
			value.Incidence_Active = item.incidenceActive;
			value.Incidence = item.incidence;
			value.OCRChart = item.ocrChart;
			value.Covered = item.covered;
			value.MessageId = item.messageId;
			value.IsCoveredCorrect = item.isCoveredCorrect;

			if (!initialDevicesLastMessageRows.find((x) => x.id === value.id)) {
				if (value.Deposit) {
					value.Deposit_Active = true;
				} else {
					// TO DO: Aún no se registra la Zona Redexis (zone_JSON) en OCRMessages
					value.Zone = item.lastMessage === null ? "SIN MENSAJES" : "NO REG";
					value.Location =
						item.location_JSON ?? item.deposit_JSON ?? "SIN MENSAJES";
					value.IndustrialZone =
						item.industrialZone_JSON ?? item.deposit_JSON ?? "SIN MENSAJES";
					value.Suffix =
						item.suffix_JSON ?? item.deposit_JSON ?? "SIN MENSAJES";
					value.Deposit = item.deposit_JSON ?? "SIN MENSAJES";
					value.Capacity = 0;
					value.Deposit_Active = false;
				}
				initialDevicesLastMessageRows.push(value);
			}
		});
	}
	return initialDevicesLastMessageRows;
};

const getCustomChartCellData = (
	deviceId,
	service,
	content,
	setContent,
	setLoading
) => {
	(async () => {
		try {
			setLoading(true);
			if (content.find((d) => d.deviceId === deviceId)) {
				setContent(content);
				setLoading(false);
			} else {
				let deviceMessages = await service.getAllDeviceMessagesById(deviceId);
				if (deviceMessages ?? deviceMessages.success) {
					deviceMessages.sort(
						(a, b) => new Date(a.createdDate) - new Date(b.createdDate)
					);
					const newData = processCustomChartCellDataFromServer(deviceMessages);
					if (newData) {
						setContent([...content, ...newData]);
					} else {
						setContent("ERROR");
					}
					setLoading(false);
				} else {
					setContent("ERROR");
					setLoading(false);
				}
			}
		} catch (error) {
			console.error(error);
			setContent("ERROR");
			setLoading(false);
		}
	})();
};

const processCustomChartCellDataFromServer = (deviceMessages) => {
	let data = [];
	deviceMessages.forEach((item) => {
		const deviceMessageDate = new Date(item.createdDate);
		const options = { year: "numeric", month: "2-digit", day: "2-digit" };
		const formattedCreatedDate = deviceMessageDate
			? deviceMessageDate.toLocaleDateString("es-ES", options)
			: "";
		data.push({
			deviceId: item.deviceId,
			name: formattedCreatedDate,
			value: item.value,
		});
	});
	return data;
};

export const getGridDevicesColumns = (setReloadTrigger, setOpenSnackbar) => {
	return [
		{
			field: "id",
			headerName: "Id Dispositivo",
			type: "number",
			hide: true,
			flex: 1,
			minWidth: 90,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
		},
		{
			field: "MessageId",
			headerName: "Id Mensaje",
			type: "number",
			hide: true,
			align: "center",
			flex: 1,
			minWidth: 90,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
		},
		{
			field: "OCRChart",
			headerName: "",
			renderCell: (value) => (
				<CustomChartCell
					deviceId={value.id}
					getDataContent={getCustomChartCellData}
				/>
			),
			align: "center",
			width: 50,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
			disableColumnMenu: true,
			sortable: false,
		},
		{
			field: "OCRImage",
			headerName: "",
			renderCell: (params) => (
				<CustomImageCell
					value={params.value}
					rowIndex={params.rowIndex}
					rowData={params.api.getRowModels()}
				/>
			),
			align: "center",
			width: 100,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
			disableColumnMenu: true,
			sortable: false,
		},
		{
			field: "Device",
			headerName: "IMEI",
			type: "string",
			align: "center",
			flex: 1,
			minWidth: 150,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
		},
		{
			field: "Zone",
			headerName: "Zona",
			type: "string",
			align: "center",
			flex: 1,
			minWidth: 170,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
		},
		{
			field: "Location",
			headerName: "Municipio",
			type: "string",
			align: "center",
			flex: 1,
			minWidth: 170,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
		},
		{
			field: "IndustrialZone",
			headerName: "Poligono",
			type: "string",
			align: "center",
			flex: 1,
			minWidth: 110,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
		},
		{
			field: "Deposit",
			headerName: "Deposito",
			type: "string",
			align: "center",
			flex: 1,
			minWidth: 130,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
		},
		{
			field: "Capacity",
			headerName: "Capacidad",
			type: "number",
			align: "center",
			flex: 1,
			minWidth: 100,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
		},
		{
			field: "Suffix",
			headerName: "Sufijo",
			type: "string",
			align: "center",
			flex: 1,
			minWidth: 80,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
		},
		{
			field: "OCRValue",
			headerName: "Valor",
			type: "number",
			align: "center",
			flex: 1,
			minWidth: 80,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
		},
		{
			field: "MessageDate",
			headerName: "Fecha Entrada",
			type: "date",
			align: "center",
			flex: 1,
			minWidth: 140,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
			valueGetter: ({ value }) => value && new Date(value),
		},
		{
			field: "Network",
			headerName: "Network",
			type: "string",
			align: "center",
			flex: 1,
			minWidth: 80,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
			valueGetter: ({ value }) =>
				value &&
				Object.keys(enumNetworkType)
					.find((k) => enumNetworkType[k] === value)
					.toString(),
		},
		{
			field: "BatteryCapacity",
			headerName: "Bateria",
			type: "number",
			align: "center",
			flex: 1,
			minWidth: 90,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
		},
		{
			field: "Incidence_Active",
			headerName: "Inc Activa",
			type: "boolean",
			align: "center",
			flex: 1,
			minWidth: 90,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
			renderCell: (params) => {
				return params.value ? "✔" : "✖";
			},
		},
		{
			field: "Covered",
			headerName: "Detección",
			type: "string",
			align: "center",
			flex: 1,
			minWidth: 90,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
			renderCell: (params) => {
				return params.value === "0"
					? "✔"
					: params.value === "1"
					? "✖"
					: params.value;
			},
		},
		{
			field: "IsCoveredCorrect",
			headerName: "Correcta",
			type: "boolean",
			align: "center",
			renderCell: (params) => {
				return (
					<input
						type='checkbox'
						checked={params.row.IsCoveredCorrect}
						onChange={() => {
							DeviceIncidenceService.checkboxHandler(
								params,
								setReloadTrigger,
								setOpenSnackbar
							);
						}}
					/>
				);
			},
			minWidth: 100,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
			disableColumnMenu: true,
			sortable: false,
		},
		{
			field: "Incidence",
			headerName: "Incidencia",
			type: "string",
			align: "center",
			flex: 1,
			minWidth: 135,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
		},
		{
			field: "Deposit_Active",
			headerName: "Dep Reg",
			type: "boolean",
			align: "center",
			flex: 1,
			minWidth: 100,
			headerClassName: "header-styles",
			cellClassName: "cell-styles",
			headerAlign: "center",
			renderCell: (params) => {
				return params.value ? "✔" : "✖";
			},
		},
	];
};

export const fetchDevicesLastMessage = (data, setData, service, setLoading) => {
	(async () => {
		try {
			setLoading(true);
			const devs = await service.getDevicesLastMessages();
			const newData = getGridDevicesRows(devs);
			newData.sort((a, b) => {
				if (a.Incidence_Active === b.Incidence_Active) {
					return new Date(b.MessageDate) - new Date(a.MessageDate); // Ordenar primero por MessageDate
				}
				return a.Incidence_Active - b.Incidence_Active; // Luego por Incidence_Active = false
			});
			if (newData) {
				setData([...data, ...newData]);
			}
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	})();
};

export const lastDeviceMessagesExists = (data) => {
	return false;
};

export function getGridDevicesInitialState() {
	return {
		pinnedColumns: { left: ["OCRImage"] },
	};
}

export const getGridDevicesRowClass = (params) => {
	const { Incidence_Active } = params.row;
	return Incidence_Active ? "incidence-row" : "";
};

export const getGridDevicesCellClass = (params) => {
	if (params.field === "OCRValue") {
		if (params.value > 20 && params.value <= 40) {
			return "warning-cell";
		} else if (params.value <= 20) {
			return "danger-cell";
		}
	}
	if (params.field === "BatteryCapacity") {
		if (params.value > 10 && params.value <= 25) {
			return "warning-cell";
		}
		if (params.value <= 10) {
			return "danger-cell";
		}
	}
	if (params.field === "Covered") {
		if (params.value === "1") {
			return "danger-cell";
		}
	}
	return "";
};
