import { createDeviceIncidence } from "./scripts/DeviceIncidenceServiceScripts";

const getDevicesIncidences = async () => {
	let response = await fetch("api/incidences", { headers: { "X-CSRF": 1 } });

	let jsonResponse = await response.json();

	let devicesIncidencesResult = jsonResponse["result"];

	let devicesIncidences = [];

	devicesIncidencesResult.forEach((deviceIncidence) => {
		devicesIncidences.push(createDeviceIncidence(deviceIncidence));
	});

	return devicesIncidences;
};

const checkboxHandler = async (params, setReloadTrigger, setOpenSnackbar) => {
	const messageId = params.row.MessageId;
	const isCoveredCorrect = params.row.IsCoveredCorrect;
	const change = !isCoveredCorrect;

	const url = `/api/Incidences/${messageId}/covered/${change}`;

	try {
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"X-CSRF": 1,
			},
		});
		if (response.ok) {
			setReloadTrigger((prevState) => prevState + 1);
		} else {
			setOpenSnackbar(true);
		}
	} catch (error) {
		setOpenSnackbar(true);
	}
};

export default { getDevicesIncidences, checkboxHandler };
