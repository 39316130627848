import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";

const MessageHistoryTable = ({ deviceData, deviceDataFields, formatDate }) => {
	return (
		<>
			<h1>Historial de Mensajes</h1>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							{Object.keys(deviceDataFields).map((field, index) => (
								<TableCell key={index}>
									<strong>{deviceDataFields[field]}</strong>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{deviceData.map((item, index) => (
							<TableRow key={index}>
								{Object.keys(deviceDataFields).map((field, subIndex) => (
									<TableCell key={subIndex}>
										{field === "createdDate" ? (
											formatDate(item[field])
										) : field === "imagePath" ? (
											<img src={item[field]} alt='Device' width='200' />
										) : (
											item[field]
										)}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default MessageHistoryTable;
