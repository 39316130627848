import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { useMediaQuery } from 'react-responsive'
import { Riple } from "react-loading-indicators";

export const PieChartWithCustomizedLabel = (props) => {

    const {
        getDataPieChart,
        service,
        LegendPayload,
        renderPieChartLabel,
        renderTooltip,
        dataKey = "value",
        loaderSize = "small",
        loaderColor = "#7ab750",
        loaderText = "",
        loaderTextColor = "",
        pieOuterRadius = 120,
        fillOuterRadius = "#8884d8",
        COLORS = ['#eb984e', '#f4d03f', '#52be80', '#48c9b0', '#5499c7', '#af7ac5', '#5d6d7e', '#cacfd2']
    } = props;
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery({ maxWidth: 767 })

    useEffect(() => { getDataPieChart(service, setContent, setLoading); }, []);

    return (
        <ResponsiveContainer width="100%" height={ 400}>
            {loading ? <Riple color={loaderColor} size={loaderSize} text={loaderText} textColor={loaderTextColor} />
                :
                <PieChart
                    margin={{ top: 10, right: 10, left: 10, bottom: 5 }}
                >
                    <Pie
                        data={content}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={(props) => renderPieChartLabel({ ...props, content }) }
                        outerRadius={pieOuterRadius}
                        fill={fillOuterRadius}
                        dataKey={dataKey}
                    >
                        {content.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Legend
                        align="center"
                        verticalAlign="bottom"
                        layout="horizontal"
                        iconSize={10}
                        payload={LegendPayload({ content: content, COLORS: COLORS })}
                    />
                    <Tooltip content={(props) => renderTooltip({ ...props, content }) } />
                </PieChart>
            }
        </ResponsiveContainer>
    );
};