import PropTypes from "prop-types";

export class DeviceLastMessage {
	constructor(
		id,
		imei,
		zone,
		location,
		industrialZone,
		deposit,
		capacity,
		suffix,
		value,
		lastMessage,
		networkType,
		electricity,
		imagePath,
		incidenceActive,
		incidence,
		ocrChart = null,
		depoCodeJSON,
		suffixJSON,
		industrialZoneJSON,
		locationJSON,
		locationJSONRaw,
		covered,
		messageId,
		isCoveredCorrect
	) {
		this.id = id;
		this.imei = imei;
		this.zone = zone;
		this.location = location;
		this.industrialZone = industrialZone;
		this.deposit = deposit;
		this.capacity = capacity;
		this.suffix = suffix;
		this.value = value;
		this.lastMessage = lastMessage;
		this.networkType = networkType;
		this.electricity = electricity;
		this.imagePath = imagePath;
		this.incidenceActive = incidenceActive;
		this.incidence = incidence;
		this.ocrChart = ocrChart;

		this.zone_JSON = null;
		this.location_JSON = locationJSON;
		this.industrialZone_JSON = industrialZoneJSON;
		this.deposit_JSON = depoCodeJSON;
		this.suffix_JSON = suffixJSON;
		this.location_JSON_Raw = locationJSONRaw;

		this.covered = covered;
		this.messageId = messageId;
		this.isCoveredCorrect = isCoveredCorrect;
	}
}

DeviceLastMessage.propTypes = {
	id: PropTypes.number.isRequired,
	imei: PropTypes.string.isRequired,
	zone: PropTypes.string,
	location: PropTypes.string,
	industrialZone: PropTypes.string,
	deposit: PropTypes.string,
	suffix: PropTypes.string,
	value: PropTypes.number,
	capacity: PropTypes.number,
	lastMessage: PropTypes.string,
	networkType: PropTypes.string,
	electricity: PropTypes.number,
	imagePath: PropTypes.string,
	incidenceActive: PropTypes.bool,
	incidence: PropTypes.string,
	ocrChart: PropTypes.any.isRequired,

	zone_JSON: PropTypes.string,
	location_JSON: PropTypes.string,
	industrialZone_JSON: PropTypes.string,
	deposit_JSON: PropTypes.string,
	suffix_JSON: PropTypes.string,
	location_JSON_Raw: PropTypes.string,

	covered: PropTypes.string,
	messageId: PropTypes.int,
	isCoveredCorrect: PropTypes.bool,
};
