import { CustomImageCell } from '../../../components/DataGrid/SignosDataGridPro/accessories/CustomImageCell';

const getGridDetailMessagesFromDeviceRows = (messages) => {
    let initialHistoricDeviceMessagesRows = [];
    if (messages && messages.length > 0) {
        messages.map((item) => {
            let value = {};
            value.ImagePath = item.imagePath;
            value.DeviceId = item.deviceId;
            value.id = item.id;
            value.DataType = item.dataType;
            value.EventType = item.eventType;
            value.Value = item.value;
            value.NetworkType = item.networkType;
            value.Electricity = item.electricity;
            value.MessageIdRef = item.messageIdRef;
            value.CreatedDate = item.createdDate;
            if (!initialHistoricDeviceMessagesRows.find(x => x.id === value.id) && value.DataType === 100) {
                initialHistoricDeviceMessagesRows.push(value);
            }
        });
    }
    return initialHistoricDeviceMessagesRows;
}

export const getGridDetailMessagesFromDeviceColumns = () => {
    return [
        {
            field: 'ImagePath', headerName: '', renderCell: CustomImageCell, align: "center",
            flex: 1, maxWidth: 100, headerClassName: 'header-styles-detail', headerAlign: 'center', disableColumnMenu: true, sortable: false
        },
        {
            field: 'DeviceId', headerName: 'Device Id', type: 'number', align: "center",
            flex: 1, minWidth: 130, headerClassName: 'header-styles-detail', editable: false, headerAlign: 'center', hide: true
        },
        {
            field: 'id', headerName: 'ID', type: 'number', align: "center",
            flex: 1, minWidth: 140, headerClassName: 'header-styles-detail', editable: false, headerAlign: 'center', hide: true
        },
        {
            field: 'CreatedDate', headerName: 'Fecha', type: 'date', align: "center",
            flex: 1, minWidth: 170, headerClassName: 'header-styles-detail', headerAlign: 'center',
            valueGetter: ({ value }) => value && new Date(value)
        },
        {
            field: 'Value', headerName: 'Valor', type: 'number', align: "center",
            flex: 1, minWidth: 150, headerClassName: 'header-styles-detail', editable: false, headerAlign: 'center'
        },
        {
            field: 'Electricity', headerName: 'Bateria', type: 'number', align: "center",
            flex: 1, minWidth: 190, headerClassName: 'header-styles-detail', headerAlign: 'center'
        },

    ]

}

export const messagesFromDeviceExists = (deviceId, data) => {
    return data.find(dm => dm.DeviceId === deviceId);
}

export const fetchMessagesFromDevice = (deviceId, data, setData, service, setLoading) => {

    if (data.find(dm => dm.DeviceId === deviceId)) { return; }

    (async () => {
        try {
            setLoading(true);
            let deviceMessages = await service.getAllDeviceMessagesById(deviceId);
            const newData = getGridDetailMessagesFromDeviceRows(deviceMessages);
            newData.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate));
            if (newData) { setData([...data, ...newData]); }
            setLoading(false);
        }
        catch (error) {
            console.error(error);
            setLoading(false);
        }
    })();
}

export function getGridDetailMessagesFromDeviceInitialState() { return { pinnedColumns: { left: [""] } }; }

export const getGridDetailMessagesFromDeviceRowClass = (params) => { return ''; }

export const getGridDetailMessagesFromDeviceCellClass = (params) => {
    if (params.field === 'Value') {
        if (params.value > 20 && params.value <= 40) { return 'warning-cell'; }
        else if (params.value <= 20) { return 'danger-cell'; }
    }
    if (params.field === 'Electricity') {
        if (params.value > 10 && params.value <= 25) { return 'warning-cell'; }
        if (params.value <= 10) { return 'danger-cell'; }
    }
    return '';
};