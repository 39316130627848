import { DeviceIncidence } from '../../../entities/DeviceIncidence'

export const createDeviceIncidence = (devIncidence) => {
    let deviceIncidence = new DeviceIncidence(
        devIncidence.id,
        devIncidence.createdDate,
        devIncidence.updatedDate,
        devIncidence.name,
        devIncidence.code,
        devIncidence.deviceId,
        devIncidence.description,
        devIncidence.isActive,
        devIncidence.deviceImei,
        devIncidence.imagePath
    );
    return deviceIncidence;
};