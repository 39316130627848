import React from "react";
import {
	Grid,
	TableContainer,
	Paper,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Button,
} from "@mui/material";

const IsCoveredGrid = ({
	data,
	currentIndex,
	updateIsCoveredCorrect,
	isCoveredCorrectState,
	toggleCorrect,
	setIsCoveredCorrectState,
	setOpenSnackBar,
}) => {
	return (
		<Grid container spacing={2} alignItems='center' justifyContent='center'>
			<Grid item>
				<TableContainer component={Paper} style={{ display: "inline-block" }}>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>Estado Imagen</TableCell>
								<TableCell
									style={{
										minWidth: "max-content",
										color:
											data[currentIndex]["Covered"] === "1" ? "red" : "green",
									}}>
									{data[currentIndex]["Covered"] === "1" ? "Error" : "OK"}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item>
				<Button
					variant='contained'
					color={isCoveredCorrectState ? "error" : "success"}
					onClick={() => {
						updateIsCoveredCorrect(
							data,
							currentIndex,
							toggleCorrect,
							isCoveredCorrectState,
							setIsCoveredCorrectState,
							setOpenSnackBar
						);
					}}>
					{isCoveredCorrectState ? "Incorrecto" : "Correcto"}
				</Button>
			</Grid>
		</Grid>
	);
};

export default IsCoveredGrid;
