import PropTypes from 'prop-types';

export class OCRMessage {
    constructor(id, deviceId, dataType, eventType, imagePath, value, networkType, electricity, messageIdRef, createdDate, location, locationRaw, industrialZone, depoCode, suffix) {
        this.id = id;
        this.deviceId = deviceId;
        this.dataType = dataType;
        this.eventType = eventType;
        this.imagePath = imagePath;
        this.value = value;
        this.networkType = networkType;
        this.electricity = electricity;
        this.messageIdRef = messageIdRef;
        this.createdDate = createdDate;
        this.JSONLocation = location;
        this.JSONLocationRaw = locationRaw;
        this.JSONIndustrialZone = industrialZone;
        this.JSONDepoCode = depoCode;
        this.JSONSuffix = suffix; 
    }
}

OCRMessage.propTypes = {
    id: PropTypes.number.isRequired,
    deviceId: PropTypes.number.isRequired,
    dataType: PropTypes.string.isRequired,
    eventType: PropTypes.string.isRequired,
    imagePath: PropTypes.string,
    value: PropTypes.string,
    networkType: PropTypes.string,
    electricity: PropTypes.number,
    messageIdRef: PropTypes.number,
    createdDate: PropTypes.instanceOf(Date).isRequired,
    JSONLocation: PropTypes.string,
    JSONLocationRaw: PropTypes.string,
    JSONIndustrialZone: PropTypes.string,
    JSONDepoCode: PropTypes.string,
    JSONSuffix: PropTypes.string,
};
