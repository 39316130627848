import React from "react";
import "./css/DashboardStyles.css";
import { useMediaQuery } from "react-responsive";
import { Container, Row, Col } from "react-bootstrap";
//#region Grid functions
import {
    getDashboardLastMessageDate,
    getDashboardTotalIncidences,
    getDashboardIncidencesByMonthAreaChart,
    getDataIncidenceByTypePieChart,
    getDataDepositValueByZoneBarChart,
    getDataDeviceByValueRange,
    getDashboardTotalDevices,
    customTooltipDepositValueByZoneBarChart,
    legendPayloadDepositValueByZoneBarChart,
    renderTooltipContentIncidencesByMonthAreaChart,
    legendPayloadPieCharts,
    renderPieChartLabelDeviceByValueRange,
    renderTooltipDeviceByValueRange,
    renderTooltipIncidenceByType,
    renderPieChartLabelIncidenceByType
} from './scripts/dashboardFunctions';
// #endregion

import { StackedBarChart as StackedBarChartDepositValueByZone } from './../../components/Dashboard/StackedBarChart';
import { PieChartWithCustomizedLabel as PieChartIncidenceByType, PieChartWithCustomizedLabel as PieChartDeviceByValueRange } from './../../components/Dashboard/PieChartWithCustomizedLabel';
import { CustomQuantityAreaChart as CustomQuantityAreaChartIncidencesByMonth } from '../../components/Dashboard/CustomQuantityAreaChart';
import { InfoCard as LastMessageCard, InfoCard as TotalIncidencesCard, InfoCard as TotalDevicesCard } from "./../../components/Dashboard/InfoCard";

import DashboardServices from "../../services/DashboardServices/DashboardServices.js";

export default function Dashboard() {

    const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

    return (
        <Container>
            <Row>
                <Col lg={3} md={12}>
                    <Col>
                        <TotalIncidencesCard
                            getDataCard={getDashboardTotalIncidences}
                            service={DashboardServices}
                            cardTitle="Incidencias Activas"
                            cardType={"bg-danger"}
                            cardTextColor={"text-white"}
                            cardHeight={"10vh"}
                        />
                    </Col>
                    <PieChartIncidenceByType
                        getDataPieChart={getDataIncidenceByTypePieChart}
                        service={DashboardServices}
                        LegendPayload={legendPayloadPieCharts}
                        renderPieChartLabel={renderPieChartLabelIncidenceByType}
                        renderTooltip={renderTooltipIncidenceByType}
                    />
                </Col>
                <Col lg={9} md={12}>
                    <CustomQuantityAreaChartIncidencesByMonth
                        getDataAreaChart={getDashboardIncidencesByMonthAreaChart}
                        service={DashboardServices}
                        firstAreaDataKey={'totalActive'}
                        firstAreaColor={"#FCB6B6"}
                        secondAreaDataKey={'totalInactive'}
                        secondAreaColor={"#82CA9D"}
                        stackId={"a"}
                        xDataKey={"concatMonthAndYear"}
                        renderTooltipContent={renderTooltipContentIncidencesByMonthAreaChart}
                    />
                </Col>

            </Row>

            <Row>
                <Col lg={3} md={12}>
                    <Col>
                        <TotalDevicesCard
                            getDataCard={getDashboardTotalDevices}
                            service={DashboardServices}
                            cardTitle="Total Dispositivos"
                            cardType={"bg-info"}
                            cardTextColor={"text-white"}
                            cardHeight={"10vh"}
                        />
                    </Col>
                    <Col>
                        <PieChartDeviceByValueRange
                            getDataPieChart={getDataDeviceByValueRange}
                            service={DashboardServices}
                            LegendPayload={legendPayloadPieCharts}
                            COLORS={['#FC543A', '#EB984E', '#5499C7', '#82ca9d']}
                            renderPieChartLabel={renderPieChartLabelDeviceByValueRange}
                            renderTooltip={renderTooltipDeviceByValueRange }
                        />
                    </Col>
                </Col>

                <Col lg={9} md={12} >
                    <StackedBarChartDepositValueByZone
                        getDataStackedBarChart={getDataDepositValueByZoneBarChart}
                        service={DashboardServices}
                        xDataKey="zone"
                        stackId="a"
                        barSize={70}
                        firstBarDataKey={'a'}
                        firstBarColor={'#FC543A'}
                        secondBarDataKey={'b'}
                        secondBarColor={'#EB984E'}
                        thirdBarDataKey={'c'}
                        thirdBarColor={'#5499C7'}
                        fourthBarDataKey={'d'}
                        fourthBarColor={'#82ca9d'}
                        CustomTooltip={customTooltipDepositValueByZoneBarChart}
                        LegendPayload={legendPayloadDepositValueByZoneBarChart}
                    />
                </Col>

            </Row>
            <Row>

            </Row>
            <Col lg={12} md={12}>
                <LastMessageCard
                    getDataCard={getDashboardLastMessageDate}
                    service={DashboardServices}
                    cardTitle="Ultimo Mensaje Registrado"
                    cardType={"bg-info"}
                    cardHeight={"10vh"}
                />
            </Col>
        </Container>
    );
}