import React from "react";

// #region DataGridPro
import SignosDataGridPro from "../../components/DataGrid/SignosDataGridPro/SignosDataGridPro";
// #endregion

//#region Grid Incidences functions
import { deviceIncidencesExists, fetchDevicesIncidences, getGridDevicesIncidencesColumns, getGridDevicesIncidencesInitialState, getGridDevicesIncidencesRowClass, getGridDevicesIncidencesCellClass } from './scripts/gridDevicesIncidencesFunctions';
// #endregion

// #region Services
import DeviceIncidenceService from "../../services/DeviceIncidenceService/DeviceIncidenceService";
// #endregion


export default function AdminDevicesIncidences() {

    return (
        <SignosDataGridPro
            // #region Required
            service={DeviceIncidenceService}
            dataRowsExists={deviceIncidencesExists}
            fetchDataRows={fetchDevicesIncidences}
            getDataColumns={getGridDevicesIncidencesColumns()}
            initialState={getGridDevicesIncidencesInitialState()}
            getRowClassName={getGridDevicesIncidencesRowClass}
            getCellClassName={getGridDevicesIncidencesCellClass}
            // #endregion

            // #region Styles optional
            tableHeight={800}
            rowHeight={50}
            defaultPagesize={50}
            // #endregion
        />
    );
}