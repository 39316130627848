import PropTypes from 'prop-types';

export class DeviceIncidence {
    constructor(id, createdDate, updatedDate, name, code, deviceId, description, isActive, deviceImei, imagePath) {
        this.id = id;
        this.createdDate = createdDate;
        this.updatedDate = updatedDate;
        this.name = name;
        this.code = code;
        this.deviceId = deviceId;
        this.description = description;
        this.isActive = isActive;
        this.deviceImei = deviceImei;
        this.imagePath = imagePath;
    }
}

DeviceIncidence.propTypes = {
    id: PropTypes.number.isRequired,
    createdDate: PropTypes.instanceOf(Date),
    updatedDate: PropTypes.instanceOf(Date),
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    deviceId: PropTypes.number.isRequired,
    description: PropTypes.string,
    isActive: PropTypes.bool.isRequired,
    imagePath: PropTypes.string,
    deviceImei: PropTypes.string.isRequired
};

