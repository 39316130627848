// #region Base
import React, { useState, useEffect, useCallback } from "react";
// #endregion
// #region CSS Styles
import "./../css/SignosDataGrid.css";
// #endregion
// #region @mui/x components
import {
	DataGridPro,
	GridToolbar /*, GRID_DETAIL_PANEL_TOGGLE_FIELD*/,
} from "@mui/x-data-grid-pro";
// #endregion

// #region @mui/material components
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
// #endregion

// #region core Components
import NoRowsOverlay from "./accessories/NoRowsOverlayImg";
// #endregion

import { LicenseInfo } from "@mui/x-license-pro";

// #region licence @mui x
LicenseInfo.setLicenseKey(
	"6f6f0d1132d5d8d7777aef41a728837bT1JERVI6NDM2ODYsRVhQSVJZPTE2ODQwNTQ5ODgwMDAsS0VZVkVSU0lPTj0x"
);
// #endregion

export default function SignosDataGridPro(props) {
	// #region props
	const {
		rows = null,
		columns = null,
		service,
		dataRowsExists,
		fetchDataRows,
		getDataColumns,
		reloadTrigger,

		// #region Styles optional
		tableHeight = 500,
		rowHeight = null,
		initialState,
		getRowClassName,
		getCellClassName,
		width,
		height,
		// #endregion

		// #region Toolbar
		withToolbar = false,
		// #endregion

		// #region Others
		onCellClickAction = false,
		// #endregion

		// #region With DetailPanel
		isDetailPanel = false,
		withDetailPanel = false,
		visibleRowsDetailPanel = 0,
		getDetailPanelContent = null,
		// #endregion

		sortingRow = "id",
		hideFooter = false, // revisar
		pagination = true,
		defaultPagesize = 50,
	} = props;
	// #endregion

	const [dataRow, setDataRow] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!isDetailPanel) {
			(async () => {
				if (!dataRowsExists(dataRow)) {
					fetchDataRows(dataRow, setDataRow, service, setLoading);
				}
			})();
		}
	}, [reloadTrigger]);

	// #region Pagination
	const [pageSize, setPageSize] = useState(defaultPagesize);
	// #endregion

	// #region WithDetailPanel
	const getDetailPanelHeight = useCallback(() => tableHeight, []);
	// #endregion

	return (
		<Box
			sx={{
				height: tableHeight,
				width: "100%",
				"& .actions": { color: "text.secondary" },
				"& .textPrimary": { vcolor: "text.primary" },
			}}>
			<DataGridPro
				style={!isDetailPanel ? { width: "100%", height: "90vh" } : {}}
				initialState={{
					sorting: { sortModel: [{ field: { sortingRow }, sort: "desc" }] },
				}}
				components={{
					Toolbar: GridToolbar,
					NoRowsOverlay: NoRowsOverlay,
					LoadingOverlay: LinearProgress,
				}}
				loading={loading}
				rows={rows ?? dataRow}
				columns={columns ?? getDataColumns}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[10, 15, 20, 50, 100, 500, 1000, 5000, 10000]}
				pagination={pagination}
				//#region Rows & Cells Styles
				initialState={initialState}
				getRowClassName={getRowClassName}
				getCellClassName={getCellClassName}
				//#endregion

				// #region DetailPanel
				getDetailPanelContent={withDetailPanel ? getDetailPanelContent : null}
				getDetailPanelHeight={() => "auto"}
				rowThreshold={2}
				// #endregion
				hideFooter={hideFooter}
			/>
		</Box>
	);
}
