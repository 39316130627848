export const ENUM_DATA_GRID_MODEL = {
    NULL: 0,
    USERS: 1,
    DEVICES: 2,
    DEVICE_TYPES: 3,
    DEVICE_STATUS: 4,
    GROUPS: 5,
    DEVICE_GROUPS: 6,
    DEVICE_INCIDENCES: 7,
    PRODUCTS: 8,
    DEVICE_DEVICE_STATUSES: 9,
}

export const enumDataType = {
    IdentificationResults: 160,
    IdentificationResultsAndImage: 100,
}

export const enumEventType = {
    DataEvent: 1,
    StatusEvent: 2,
    ErrorEvent: 3
}

export const enumNetworkType = {
    NBIOT: 2
}