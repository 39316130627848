import React from "react";
import {
	Card,
	CardContent,
	Box,
	Table,
	TableContainer,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	Grid,
} from "@mui/material";

const ImageCard = ({ data, currentIndex, dataFields, formatDate }) => {
	return (
		<Card>
			<CardContent>
				<Grid container spacing={4}>
					{/* Image */}
					<Grid item xs={12}>
						<Box display='flex' justifyContent='center'>
							<img
								src={data[currentIndex].OCRImage || ""}
								alt='OCRImage'
								style={{
									maxWidth: "400px",
									width: "100%",
									height: "auto",
									borderRadius: "5px",
								}}
							/>
						</Box>
					</Grid>

					{/* Data */}
					<Grid item xs={12}>
						<TableContainer component={Paper}>
							<Table>
								<TableBody>
									{Object.keys(dataFields).map((field) => (
										<TableRow key={field}>
											<TableCell>{dataFields[field]}</TableCell>
											<TableCell>
												{field === "MessageDate" ? (
													formatDate(data[currentIndex][field])
												) : field === "Incidence" ? (
													<span
														style={{
															color: data[currentIndex][field]
																? "red"
																: "green",
														}}>
														{data[currentIndex][field] || "N/A"}
													</span>
												) : (
													data[currentIndex][field]
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default ImageCard;
