import React, { useState } from 'react';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive'

export const CustomImageCell = ({ value }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [scale, setScale] = useState(1);
    const handleMouseEnter = () => { setScale(1.2); };
    const handleMouseLeave = () => { setScale(1); };
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const modalDimensions = {
        maxWidth: isMobile ? '90%' : '60%',
    };
    const imgDimensions = {
        objectFit: 'contain',
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 'auto',
        display: 'block'
    };
    return (
        value ?
        <>
            <img
                src={value}
                style={{
                    objectFit: 'cover',
                    width: '70%',
                    height: '95%',
                    borderRadius: '15px',
                    boxShadow: '2px 2px 4px #999',
                    border: '1px solid #ccc',
                    transition: 'transform 0.2s ease-in-out',
                    transform: `scale(${scale})`
                }}
                onClick={() => setIsModalOpen(!isModalOpen)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                style={{
                    overlay: {
                        background: 'transparent',
                        border: '0',
                        overflow: 'hidden',
                        zIndex: '9998'
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: 'auto',
                        height: 'auto',
                        padding: '0',
                        borderRadius: '20px',
                        ...modalDimensions,
                    }
                }}
                ariaHideApp={false}
            >
                <img
                    src={value}
                    style={{

                        borderRadius: '20px',
                        boxShadow: '2px 2px 4px #999',
                        border: '1px solid #ccc',
                        transition: 'transform 0.2s ease-in-out',
                        ...imgDimensions,
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
            </Modal>

            </> : <></>
    );
};
