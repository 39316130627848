import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, CartesianAxis } from 'recharts';
import { useMediaQuery } from 'react-responsive';
import { Riple } from "react-loading-indicators";

export const CustomQuantityAreaChart = (props) => {
    const {
        getDataAreaChart,
        service,
        firstAreaDataKey,
        firstAreaColor,
        secondAreaDataKey,
        secondAreaColor,
        renderTooltipContent,
        stackId = "a",
        xDataKey = "value",
        areaType = "monotone",
        thirdAreaDataKey = null,
        thirdAreaColor = null,
        fourthAreaDataKey,
        fourthAreaColor = null,
        YAxisName = "Total",
        loaderSize = "small",
        loaderColor = "#7ab750",
        loaderText = "",
        loaderTextColor = ""
    } = props;

    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 })

    useEffect(() => { getDataAreaChart(service, setContent, setLoading); }, []);

    return (
        <ResponsiveContainer width="100%" height={400}>
            {loading ?
                <Riple color={loaderColor} size={loaderSize} text={loaderText} textColor={loaderTextColor} />
                :
                <AreaChart
                    width={500}
                    height={400}
                    data={content}
                    stackOffset={undefined}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0, }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={xDataKey} tick={{ fontWeight: 'bold' }} />
                    <YAxis name={YAxisName} tick={{ fontWeight: 'bold' }} />
                    <Tooltip content={(props) => renderTooltipContent({ ...props, content })} />
                    <Area type={areaType} dataKey={firstAreaDataKey} stackId={stackId} stroke={firstAreaColor} fill={firstAreaColor} />
                    <Area type={areaType} dataKey={secondAreaDataKey} stackId={stackId} stroke={secondAreaColor} fill={secondAreaColor} yAxisId={0} />
                    {thirdAreaDataKey ? <Area type={areaType} dataKey={thirdAreaDataKey} stackId={stackId} stroke={thirdAreaColor} fill={thirdAreaColor} /> : <></>}
                    {fourthAreaDataKey ? <Area type={areaType} dataKey={fourthAreaDataKey} stackId={stackId} stroke={fourthAreaColor} fill={fourthAreaColor} /> : <></>}
                </AreaChart>
            }
        </ResponsiveContainer>
    );
};
