export const updateIsCoveredCorrect = async (
	data,
	setData,
	currentIndex,
	toggleCorrect,
	isCoveredCorrectState,
	setIsCoveredCorrectState,
	setOpenSnackBar
) => {
	const messageId = data[currentIndex].MessageId;
	const change = !isCoveredCorrectState;
	const url = `/api/Incidences/${messageId}/covered/${change}`;

	try {
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"X-CSRF": 1,
			},
		});
		if (response.ok) {
			let updatedData = [...data];
			toggleCorrect(isCoveredCorrectState, setIsCoveredCorrectState);
			updatedData[currentIndex].IsCoveredCorrect = change;
			setData(updatedData);
		} else {
			setOpenSnackBar(true);
		}
	} catch (error) {
		setOpenSnackBar(true);
	}
};

export const updateRevised = async (
	data,
	setData,
	currentIndex,
	revised,
	setOpenSnackBar
) => {
	const messageId = data[currentIndex].MessageId;
	const url = `/api/Incidences/${messageId}/revised/${revised}`;

	try {
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"X-CSRF": 1,
			},
		});
		if (response.ok) {
			let updatedData = [...data];
			updatedData[currentIndex].Revised = revised;
			setData(updatedData);
		} else {
			setOpenSnackBar(true);
		}
	} catch (error) {
		setOpenSnackBar(true);
	}
};
